/* Custom scrollbar for Webkit-based browsers */
.scrollspy-example-2::-webkit-scrollbar {
  width: 4px;
}

.scrollspy-example-2::-webkit-scrollbar-thumb {
  background: #a0a4a8; /* Customize the color */
  border-radius: 2px;
}

.scrollspy-example-2::-webkit-scrollbar-thumb:hover {
  background: #a0a4a8; /* Customize the hover color */
}

.scrollspy-example-2::-webkit-scrollbar-track {
  background: #f1f1f1; /* Customize the track color */
  border-radius: 2px;
}

/* Custom scrollbar for Firefox */
.scrollspy-example-2 {
  scrollbar-width: thin;
  border-radius: 3px;
  scrollbar-color: #80a4c9 #f1f1f1;
}

/* Topic list scrollbar for Webkit-based browsers */
.nav-pills::-webkit-scrollbar {
  width: 4px;
}

.nav-pills::-webkit-scrollbar-thumb {
  background: #007bff; /* Customize the color */
  border-radius: 2px;
}

.nav-pills::-webkit-scrollbar-thumb:hover {
  background: #0056b3; /* Customize the hover color */
}

.nav-pills::-webkit-scrollbar-track {
  background: #f1f1f1; /* Customize the track color */
  border-radius: 2px;
}

/* Topic list scrollbar for Firefox */
.nav-pills {
  scrollbar-width: thin;
  scrollbar-color: #007bff #f1f1f1;
}
@media (max-width: 400px) {
  .topDiv {
    max-width: 100vw;
    max-height: 100vh;
  }
  .topicBTN {
    font-size: 9px !important;
  }
  .topicData {
    font-size: 10px !important;
  }
  .topicData h1,
  .topicData h2,
  .topicData h3,
  .topicData h4,
  .topicData h5,
  .topicData h6 {
    font-size: 11px !important;
  }
  .topicHead {
    font-size: 12px !important;
  }
}

.section {
  margin-bottom: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}
table,
th,
td {
  border: 1px solid #ddd;
}
th,
td {
  padding: 8px;
  text-align: left;
}
th {
  background-color: #f2f2f2;
}
