/* 2x2 Grid layout for questions */
.question-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 columns */
  /* Spacing between grid items */
}

.grid-item {
  page-break-inside: avoid; /* Prevents splitting question content across pages */
}

@media print {
  body {
    margin: 0;
    padding: 0;
    width: 210mm; /* A4 width */
    height: 297mm; /* A4 height */
    overflow: hidden; /* Prevent scrolling during print */
  }

  .grid-item {
    page-break-inside: avoid; /* Ensures question blocks stay together on one page */
    page-break-before: auto; /* Try to start new pages cleanly */
  }

  .question-grid {
    grid-template-columns: repeat(2, 1fr); /* 2 columns */
    grid-auto-rows: min-content;
  }
}
