.selection-container {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.department-select {
  flex: 1;
  margin-right: 20px;
}

.department-container {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.source-department,
.destination-department {
  border: 1px solid #ccc;
  padding: 20px;
  width: 45%;
  min-height: 200px;
}

.subject-item,
.topic-item {
  padding: 10px;
  margin: 10px 0;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.topic-container {
  margin-left: 20px;
  padding-left: 10px;
  border-left: 2px solid #eee;
}
.active-animation {
  background-image: linear-gradient(90deg, rgb(2, 2, 2) 50%, transparent 50%),
    linear-gradient(90deg, rgb(2, 2, 2) 50%, transparent 50%),
    linear-gradient(0deg, rgb(2, 2, 2) 50%, transparent 50%),
    linear-gradient(0deg, rgb(2, 2, 2) 50%, transparent 50%);
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: 15px 2px, 15px 2px, 2px 15px, 2px 15px;
  background-position: left top, right bottom, left bottom, right top;
  animation: border-dance 1s infinite linear;
}

@keyframes border-dance {
  0% {
    background-position: left top, right bottom, left bottom, right top;
  }

  100% {
    background-position: left 15px top, right 15px bottom, left bottom 15px,
      right top 15px;
  }
}

.active-dropLocation {
  background-image: linear-gradient(
      90deg,
      rgb(85, 157, 240) 50%,
      transparent 50%
    ),
    linear-gradient(90deg, rgb(85, 157, 240) 50%, transparent 50%),
    linear-gradient(0deg, rgb(85, 157, 240) 50%, transparent 50%),
    linear-gradient(0deg, rgb(85, 157, 240) 50%, transparent 50%);
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: 15px 2px, 15px 2px, 2px 15px, 2px 15px;
  background-position: left top, right bottom, left bottom, right top;
  animation: border-dance 1s infinite linear;
}

@keyframes border-dance {
  0% {
    background-position: left top, right bottom, left bottom, right top;
  }

  100% {
    background-position: left 15px top, right 15px bottom, left bottom 15px,
      right top 15px;
  }
}
.from-border {
  border: 1px solid rgb(0, 0, 0);
}
