h5 {
  margin: 0px;
  font-size: 1.4em;
  font-weight: 700;
}

p {
  font-size: 12px;
}

.center {
  /* height: 70vh; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* End Non-Essential  */

.property-card {
  height: 18em;
  width: 14em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  -webkit-transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  border-radius: 16px;
  overflow: hidden;
  /* -webkit-box-shadow: 15px 15px 27px #e1e1e3, -15px -15px 27px #ffffff; */
  /* box-shadow: 15px 15px 27px #e1e1e3, -15px -15px 27px #ffffff; */
}

/* ^-- The margin bottom is necessary for the drop shadow otherwise it gets clipped in certain cases. */

/* Top Half of card, image. */

.property-image {
  filter: blur(0.5px);
  -webkit-filter: blur(0.5px);
  height: 6em;
  width: 14em;
  padding: 1em 2em;
  position: absolute;
  top: 0px;
  -webkit-transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  /* background-image: url("https://cdn.photographylife.com/wp-content/uploads/2017/01/What-is-landscape-photography.jpg"); */
  background-size: cover;
  background-repeat: no-repeat;
}

/* Bottom Card Section */

.property-description {
  background-color: #fafafc;
  height: 12em;
  width: 14em;
  position: absolute;
  bottom: 0em;
  -webkit-transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  padding: 0.5em 1em;
  text-align: center;
}

/* Social Icons */

.property-social-icons {
  width: 1em;
  height: 1em;
  background-color: rgb(0, 0, 0);
  position: absolute;
  bottom: 1em;
  left: 1em;
  -webkit-transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
}

/* Property Cards Hover States */

.property-card:hover .property-description {
  height: 0em;
  padding: 0px 1em;
}

.property-card:hover .property-image {
  height: 18em;
  /* filter: blur(1px);
  -webkit-filter: blur(1px); */
  /* background-image: url(""); */
  /* background-color: rgb(25, 2, 56); */
  /* background-image: url("../../img/trendingVideo.gif"); */
}

.property-card:hover .property-social-icons {
  background-color: white;
}

.property-card:hover .property-social-icons:hover {
  background-color: blue;
  cursor: pointer;
}
.property-image-title {
  text-align: center;
  position: Relative;
  top: 30%;
  opacity: 0;
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1) 0.2s;
  color: rgb(0, 0, 0);
  font-size: 1.2em;
}

.property-card:hover .property-image-title {
  opacity: 1;
}
